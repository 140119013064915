import React, { useState } from "react"
import Modal from "./modal"
import SpeakerForm from "./speakerForm"
import { AnimatePresence } from "framer-motion"

function BeFeaturedBanner() {
  const [modalOpen, setModalOpen] = useState(false)
  return (
    <>
      <AnimatePresence exitBeforeEnter>
        {modalOpen && (
          <Modal
            open={modalOpen}
            onClose={() => setModalOpen(false)}
            key="Be Featured Banner"
          >
            <SpeakerForm />
          </Modal>
        )}
      </AnimatePresence>

      <section className="align-center flex flex-col justify-center py-24 w-full h-80 bg-featured-speaker-callout bg-cover bg-center bg-no-repeat lg:py-32 lg:h-auto">
        <div className="mx-auto w-10/12 max-w-2xl text-center space-y-4">
          <h2 className="text-secondary-200 text-2xl italic font-bold lg:text-4xl">
            Be featured at an Autos2050® event
          </h2>
          <button
            onClick={() => setModalOpen(true)}
            className="min-w-150 px-4 py-2 text-black hover:text-white font-bold hover:bg-black border-2 border-black rounded-full focus:outline-none transition"
          >
            LEARN MORE
          </button>
        </div>
      </section>
    </>
  )
}

export default BeFeaturedBanner
