import React, { useState } from "react"
import encode from "../components/encode"
import autosLogo from "../images/logos/Autos2050_Logo_Small.png"

function SpeakerForm() {
  const [state, setState] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    additionalInformation: "",
  })

  const [formSuccess, setFormSuccess] = useState(false)

  const handleInputChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name

    setState({
      ...state,
      [name]: value,
    })
  }

  const handleSubmit = event => {
    event.preventDefault()
    fetch("/speaker", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": event.target.getAttribute("name"),
        ...state,
      }),
    })
      .then(() => setFormSuccess(true))
      .catch(error => alert(error))
  }

  if (formSuccess)
    return (
      <div className="flex flex-col items-center justify-center mx-auto p-8 w-full max-w-xl text-center bg-white space-y-4 lg:space-y-8">
        <img src={autosLogo} alt="Autos2050 Logo" className="w-32 lg:w-40" />

        <h1 className="text-xl font-bold lg:text-3xl">
          Form was submitted successfully!
        </h1>
        <p>A member of our team will be in touch.</p>
      </div>
    )

  return (
    <form
      name="Speaker Consideration"
      method="POST"
      data-netlify="true"
      onSubmit={handleSubmit}
      className="flex flex-col items-center justify-center mx-auto p-2 w-full max-w-xl text-left bg-white space-y-4 lg:p-6 lg:space-y-8"
    >
      <input type="hidden" name="form-name" value="Speaker Consideration" />

      <div>
        <img src={autosLogo} alt="Autos2050 Logo" className="w-32 lg:w-40" />
      </div>

      <h3 className="max-w-lg text-center text-base font-bold md:text-lg lg:text-2xl">
        Fill out the form to be considered for a speaking opportunity
      </h3>

      <div className="grid gap-8 grid-cols-1 w-full lg:grid-cols-2">
        <div>
          <label htmlFor="firstName" className="text-sm lg:text-base">
            First Name*
          </label>
          <input
            type="text"
            name="firstName"
            id="firstName"
            className="px-2 w-full h-full border-2 border-gray-300 rounded-sm"
            onChange={handleInputChange}
          />
        </div>

        <div>
          <label htmlFor="lastName" className="text-sm lg:text-base">
            Last Name*
          </label>
          <input
            type="text"
            name="lastName"
            id="lastName"
            className="px-2 w-full h-full border-2 border-gray-300 rounded-sm"
            onChange={handleInputChange}
          />
        </div>

        <div>
          <label htmlFor="email" className="text-sm lg:text-base">
            Email*
          </label>
          <input
            type="email"
            name="email"
            id="email"
            className="px-2 w-full h-full border-2 border-gray-300 rounded-sm"
            onChange={handleInputChange}
          />
        </div>

        <div>
          <label htmlFor="phoneNumber" className="text-sm lg:text-base">
            Phone Number*
          </label>
          <input
            type="tel"
            name="phoneNumber"
            id="phoneNumber"
            className="px-2 w-full h-full border-2 border-gray-300 rounded-sm"
            onChange={handleInputChange}
          />
        </div>

        <div className="lg:col-span-2">
          <label
            htmlFor="additionalInformation"
            className="text-sm lg:text-base"
          >
            Additional Information
          </label>
          <textarea
            name="additionalInformation"
            id="additionalInformation"
            cols="20"
            rows="10"
            className="p-2 w-full h-16 border-2 border-gray-300 rounded-sm lg:h-64"
            onChange={handleInputChange}
          ></textarea>
        </div>
      </div>

      <div>
        <button
          type="submit"
          className="border-2
						font-bold
						text-black
						border-black
						py-2
						px-4
						rounded-full
						min-w-150
						transition
						focus:outline-none
						hover:bg-black
						hover:text-white"
        >
          SUBMIT
        </button>
      </div>
      <div>
        <a
          href="https://www.autosinnovate.org/privacy-policy"
          target="_blank"
          rel="noreferrer noopener"
          className="hover:text-gray-600 underline"
        >
          Privacy Policy
        </a>
      </div>
    </form>
  )
}

export default SpeakerForm
