import React, { useEffect, useState } from "react"
import { FaBuilding, FaUser } from "react-icons/fa"

function LeaderCard({
  name,
  position,
  image,
  bio,
  company,
  companyLogo,
  shouldOpen = false,
  id,
}) {
  const [drawerOpen, setDrawerOpen] = useState(shouldOpen)
  useEffect(() => {
    setDrawerOpen(shouldOpen)
  }, [shouldOpen])

  return (
    <>
      <div className="leader-card relative m-4 w-64" id={id}>
        <div className="leader-card-height relative bg-white border-b-8 border-secondary-200">
          {image ? (
            <img
              className="relative mx-auto w-full h-56 object-cover object-center"
              src={image}
              alt={name || "Thought Leader"}
            />
          ) : (
            <div className="relative grid place-items-center mx-auto w-full h-56 text-white text-9xl bg-secondary-200">
              <FaUser />
            </div>
          )}

          <div className="relative -mt-4 px-6 py-12 text-gray-500 space-y-2">
            <h3 className="text-lg font-bold">{name || "Thought Leader"}</h3>
            <p className="text-xs font-bold">{company}</p>
            <p className="text-xs italic">{position}</p>
          </div>
          {bio && (
            <button
              className="absolute bottom-2 right-0 px-4 py-2 text-primary text-xs font-extrabold focus:outline-none"
              onClick={() => setDrawerOpen(true)}
            >
              READ BIO
            </button>
          )}
        </div>
        <div
          className={`bio-close z-10 opacity-0 ${drawerOpen ? "" : "hidden"}`}
          onClick={() => setDrawerOpen(false)}
          onKeyDown={() => setDrawerOpen(false)}
          role="button"
          tabIndex="0"
        >
          Close Drawer
        </div>
        <div
          className={`drawer-width relative transition-all  text-gray-500 ${
            drawerOpen
              ? "min-h-full opacity-100 my-8 overflow-visible pointer-events-auto"
              : "max-w-0 max-h-0 opacity-0 overflow-hidden pointer-events-none"
          } `}
        >
          <button
            className="absolute z-20 right-0 top-0 w-8 h-8 text-white focus:outline-none"
            onClick={() => setDrawerOpen(false)}
          >
            ✖
          </button>
          <div className="flex">
            {companyLogo ? (
              <img
                src={companyLogo}
                className="hidden mr-6 mt-2 w-24 h-24 bg-white rounded-full object-contain object-center md:block"
                alt={company}
              />
            ) : (
              <div className="grid hidden place-items-center mr-6 mt-2 mx-auto p-8 w-24 h-full text-center text-3xl bg-white rounded-full md:block">
                <FaBuilding />
              </div>
            )}

            <div className="">
              <div className="my-4">
                <h3 className="text-lg font-bold lg:text-xl">{name}</h3>
                <p className="italic">
                  <span className="font-bold">{company}</span> / {position}
                </p>
              </div>
              <p className="whitespace-pre-line">{bio}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default LeaderCard
