import React from "react"
import { Link, graphql } from "gatsby"
import FutureDriven from "../../images/logos/future_driven_logo.png"
import Autos2050Logo from "../../images/logos/Autos2050_Logo_KO.png"
import OvertakeLogo from "../../images/logos/overtake_logo.png"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import LeaderCard from "../../components/leaderCard"
import BeFeaturedBanner from "../../components/beFeaturedBanner"

import { motion } from "framer-motion"

function ProgramsPage({ data, location }) {
  const site = (data || {}).site
  console.log(site)

  return (
    <Layout title="programs">
      <Seo title="Programs" />
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <section className="align-center flex justify-center py-40 w-full bg-programs-page-header-colorized bg-cover bg-center bg-no-repeat">
          <h1 className="text-white text-4xl italic font-bold lg:text-6xl xl:text-7xl">
            PROGRAMS
          </h1>
        </section>
        <section className="align-center flex flex-col justify-center py-24 w-full bg-white-left-starburst bg-cover bg-center bg-no-repeat">
          <div className="mx-auto text-center space-y-4">
            <h2 className="mb-16 text-gray-500 text-2xl font-bold lg:text-4xl xl:text-5xl">
              Autos2050® Programs
            </h2>
            <div className="align-center flex flex-wrap justify-center">
              <div className="grid grid-rows-3 place-items-center m-4 px-2 w-80 h-80 text-white bg-dark-200 box-content">
                <p className="text-sm font-bold">FORUM SERIES</p>
                <img
                  className="-mt-8"
                  src={FutureDriven}
                  alt="Future Driven Logo"
                />
                <Link to="/programs/future-driven">
                  <button className="min-w-300 px-8 py-2 hover:text-black text-sm font-bold hover:bg-secondary-100 border-2 border-secondary-100 rounded-full focus:outline-none transition">
                    LEARN MORE
                  </button>
                </Link>
              </div>
              <div className="grid grid-rows-3 place-items-center m-4 px-2 w-80 h-80 text-white bg-dark-200 box-content">
                <p className="text-sm font-bold">VIRTUAL EVENT</p>
                <img
                  className="-mt-4"
                  src={Autos2050Logo}
                  alt="Autos 2050 Logo"
                />
                <Link to="/past-events/summit-2021">
                  <button className="min-w-300 px-8 py-2 hover:text-black text-sm font-bold hover:bg-secondary-200 border-2 border-secondary-200 rounded-full focus:outline-none transition">
                    LEARN MORE
                  </button>
                </Link>
              </div>
              <div className="grid grid-rows-3 place-items-center m-4 px-2 w-80 h-80 text-white bg-dark-200 box-content">
                <p className="text-sm font-bold">PODCAST SERIES</p>
                <img className="-mt-8" src={OvertakeLogo} alt="Overtake Logo" />
                <Link to="/programs/overtake">
                  <button className="min-w-300 px-8 py-2 hover:text-black text-sm font-bold hover:bg-primary border-2 border-primary rounded-full focus:outline-none transition">
                    LEARN MORE
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </section>
        <section className="align-center flex flex-col justify-center py-24 w-full bg-light-100 bg-cover bg-center bg-no-repeat">
          <div className="mx-auto text-center space-y-4">
            <h2 className="mb-16 text-gray-500 text-xl font-bold lg:text-2xl xl:text-3xl">
              {new Date().getFullYear()} Thought Leaders
            </h2>
            <div className="row-wrap flex mx-auto text-left">
              {site &&
                site.edges
                .filter((leader) => {
                  return leader.node.thoughtLeader === true
                })
                .map((leader, i) => {
                  if (typeof window !== `undefined`) {
                    return (
                      <LeaderCard
                        key={i}
                        name={leader.node?.name}
                        id={leader.node?.name}
                        position={leader.node?.position}
                        image={leader.node?.image?.asset?.url}
                        bio={leader.node?.bio}
                        company={leader.node?.company?.name}
                        companyLogo={leader.node?.company?.logo?.asset?.url}
                        shouldOpen={
                          decodeURIComponent(
                            window.location.hash.split("#")[1]
                          ) === leader.node?.name
                        }
                      />
                    )
                  }
                  return <></>
                })}
            </div>
          </div>
        </section>
        <BeFeaturedBanner />
      </motion.div>
    </Layout>
  )
}

export default ProgramsPage

export const query = graphql`
  {
    site: allSanityPerson(sort: { fields: name, order: ASC }) {
      edges {
        node {
          image {
            asset {
              url
            }
          }
          company {
            url
            name
            logo {
              asset {
                url
              }
            }
          }
          bio
          position
          name
          thoughtLeader
        }
      }
    }
  }
`
